import { DatePicker, Modal, Row, Col, Select, Button, Space, Form, Spin, message } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValue } from "../redux/filterValues";
import { hideFilterModal } from "../redux/filterModal";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from 'dayjs';
import { URL } from "./Helper/MainURL";
const defaultRange = [dayjs('2024-08-01')];
const { RangePicker } = DatePicker;
function getLast12MonthsTimescale() {
  const dateString = "Tue May 1 2024 16:44:15 GMT+0530 (India Standard Time)";
  const today = new Date(dateString);
  const timescale = [];

  for (let i = 12; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);

    timescale.push(formatDate(date));
  }
  return [timescale[0], timescale[11]];
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}-01`;
}
function formateDateProper(dateArray) {
  if (dateArray.length == 2) {
    return [moment(dateArray[0].$d).format("YYYY-MM-DD"), moment(dateArray[1].$d).format("YYYY-MM-DD")];
  } else {
    return [dateArray];
  }
}

const last12MonthsTimescale = getLast12MonthsTimescale();
const today = new Date();

const lastMonth = new Date(today);
lastMonth.setMonth(lastMonth.getMonth() - 1);

const FilterModal = (props) => {
  const filterValues = useSelector((state) => state.filterValue);
  const { activeTab, activeTabTrends, name, activePromo, activePromo1, PromotionsTrendsflag, activeTabProductSnapshot, dashboard } = props;
  const { isModalOpen, handleOk, handleCancel, setCalApi, calApi } = props;
  const [form] = Form.useForm();
  const [selectedDates, setSelectedDates] = useState(["Oct-23"]);
  const [seletcedRange, setselectedRange] = useState([]);
  const [Market_SegmentCount, setMarket_SegmentCount] = useState(filterValues?.MarketSegment?.length);
  const [CompetitiveCount, setCompetitiveCount] = useState(0);
  const [PromoTypeLengthCount, setPromoTypeLengthCount] = useState(0);
  const [CategoryCount, setCategoryCount] = useState(0);
  const [categoryset, setcategoryset] = useState([]);
  const [ProteinTypeCount, setProteinTypeCount] = useState(0);
  const [itemCount, setItemCout] = useState(0);
  const [cityCount, setcityCount] = useState(1);
  const [TimescaleValue, setTimescaleValue] = useState([]);
  const [TimePeriodPromo, setTimePeriodPromo] = useState([]);
  const [MarketsegmentValue, setMarketsegmentValue] = useState([]);
  const [CompetitivesetValue, setCompetitivesetValue] = useState([]);
  const [CategoryValue, setCategoryValue] = useState([]);
  const [ProteinTypeValue, setProteinTypeValue] = useState([]);
  const [ChannelValue, setChannelValue] = useState([]);
  const [ItemValue, setItemValue] = useState([]);
  const [RegionValue, setRegionValue] = useState([]);
  const [OpeningsRegionValue, setOpeningsRegionValue] = useState([]);
  const [PromoTypeValue, setPromoTypeValue] = useState([]);
  const [PromoType2Value, setPromoType2Value] = useState([]);
  const [PromoTypeLength, setPromoTypeLength] = useState([]);
  const [SourceTypeValue, setSourceTypeValue] = useState([]);
  const [CityValue, setCityValue] = useState([]);
  const [ProductSize, setProductSize] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [TimePeriodPromoFlag, setTimePeriodPromoFlag] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getFilterData();
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error fetching data. Please try again.");
      }
    };

    fetchData();
  }, [activeTab, filterValues]);
  useEffect(() => {
    getTimeScaleData();
  }, []);
  const getTimeScaleData = () => {
    fetch(`${URL}/filter/timescale-filter`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dashboard_type: activeTab === 1 ? "Region" : activeTab === 2 ? "Channel" : "Variation", email: localStorage.getItem("email"), TimePeriodPromo: filterValues.TimePeriodPromo }),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setTimescaleValue(data?.filters);
          setTimePeriodPromo(data?.TimePeriodPromo);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
    fetch(`${URL}/filter/promo-length`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        response.json().then(function (data) {
          setPromoTypeLength(data.item);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getFilterData = async () => {
    setFetchingData(true);
    try {
      let payLoad = {
        email: localStorage.getItem("email"),
        filters: {
          TimeScale: filterValues.TimeScale ? filterValues.TimeScale : [],
          Market_Segment: filterValues.MarketSegment ? filterValues.MarketSegment : [],
          Competitive_Set: filterValues.CompetitiveSet ? filterValues.CompetitiveSet : [],
          Category: filterValues.Category ? filterValues.Category : [],
          Protein_Type: filterValues.ProteinType ? filterValues.ProteinType : [],
          City: filterValues.City ? filterValues.City : [],
          Item: filterValues.Item ? filterValues.Item : [],
          Channel: filterValues.Channel ? filterValues.Channel : [],
          Promo_Type: filterValues.PromoType ? filterValues.PromoType : [],
        },
        country: localStorage.getItem("country"),
        dashboard: dashboard,
      };
      const commonFiltersResponse = await fetch(`${URL}/filter/common-filters`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payLoad),
      });

      const commonFiltersData = await commonFiltersResponse.json();
      setMarketsegmentValue(commonFiltersData?.segment);
      setCategoryValue(commonFiltersData?.category);
      setCompetitivesetValue(commonFiltersData?.brand);
      setProteinTypeValue(commonFiltersData?.protein_type);
      setChannelValue(commonFiltersData?.channel);
      setCityValue(commonFiltersData?.city);
      setProductSize(commonFiltersData?.size);
      setItemValue(commonFiltersData?.item);
      setPromoTypeValue(commonFiltersData?.promo_type);
      setPromoType2Value(commonFiltersData?.promo_type2);
      setSourceTypeValue(commonFiltersData?.source_type);
      setRegionValue(commonFiltersData?.region);
      setOpeningsRegionValue(commonFiltersData?.region_openings)
      setFetchingData(false);
    } catch (error) {
      console.error("Fetch error:", error);
      message.error("please close Filter modal and open again");
    }
  };

  const onFinish = (values) => {
    const updatedData = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (value == null) {
          return [];
        }
        return [key, Array.isArray(value) ? value : [value]];
      })
    );

    if (seletcedRange.length != 0 && seletcedRange[0] != null && seletcedRange[0] != "") {
      let selectctedRangeNew = formateDateProper(seletcedRange);
      updatedData.TimescalesTrend = selectctedRangeNew;
    } else if (filterValues.TimescalesTrend.length != 0) {
      updatedData.TimescalesTrend = filterValues.TimescalesTrend;
    } else {
      updatedData.TimescalesTrend = [...last12MonthsTimescale];
    }
    dispatch(setFilterValue(updatedData));
    setCalApi(calApi + 1);
    dispatch(hideFilterModal());
  };

  const handleDateChange = (dates) => {
    setSelectedDates([dates]);
  };
  const handleDateRangeChange = (dateStrings) => {
    setselectedRange(dateStrings);
  };
  const handlePTLChange = (data) => {
    setPromoTypeLengthCount(data.length);
  };
  const handleMsChange = (data) => {
    setMarket_SegmentCount(data.length);
    try {
      setCompetitiveCount(0);
      setCategoryCount(0);
      setItemCout(0);
      dispatch(
        setFilterValue({
          Timescales: filterValues.TimeScales ? filterValues.TimeScales : [],
          MarketSegment: data,
          CompetitiveSet: [],
          TimescalesTrend: filterValues.TimescalesTrend,
          Category: [],
          ProteinType: filterValues.ProteinType ? filterValues.ProteinType : [],
          Channel: filterValues.Channel ? filterValues.Channel : [],
          City: filterValues.City ? filterValues.City : [],
          Item: [],
          ProductSize: filterValues.ProductSize ? filterValues.ProductSize : [],
          ComparisionType: filterValues.ComparisionType ? filterValues.ComparisionType : [],
          PromoType: filterValues.PromoType ? filterValues.PromoType : [],
          PromoType2: filterValues.PromoType2 ? filterValues.PromoType2 : [],
          SourceType: filterValues.SourceType ? filterValues.SourceType : [],
          Offer: filterValues.Offer ? filterValues.Offer : [],
        })
      );
      form.setFieldsValue({
        CompetitiveSet: [],
        Category: [],
        Item: [],
      });
    } catch (error) {
      console.error("Error resetting fields:", error);
    }
  };
  const handleCsChange = (data) => {
    setCompetitiveCount(data.length);

    setCategoryCount(0);
    setItemCout(0);
    dispatch(
      setFilterValue({
        Timescales: filterValues.TimeScales ? filterValues.TimeScales : [],
        MarketSegment: filterValues.MarketSegment ? filterValues.MarketSegment : [],
        CompetitiveSet: data,
        TimescalesTrend: filterValues.TimescalesTrend,
        Category: [],
        ProteinType: filterValues.ProteinType ? filterValues.ProteinType : [],
        Channel: filterValues.Channel ? filterValues.Channel : [],
        City: filterValues.City ? filterValues.City : [],
        Item: [],
        ProductSize: filterValues.ProductSize ? filterValues.ProductSize : [],
        ComparisionType: filterValues.ComparisionType ? filterValues.ComparisionType : [],
        PromoType: filterValues.PromoType ? filterValues.PromoType : [],
        PromoType2: filterValues.PromoType2 ? filterValues.PromoType2 : [],
        SourceType: filterValues.SourceType ? filterValues.SourceType : [],
        Offer: filterValues.Offer ? filterValues.Offer : [],
      })
    );
    form.setFieldsValue({
      Category: [],
      Item: [],
    });
  };
  const handleCcChange = (data) => {
    if (!data || data == null) {
      setcategoryset([]);
      dispatch(
        setFilterValue({
          Timescales: filterValues.Timescales,
          MarketSegment: filterValues.MarketSegment,
          CompetitiveSet: filterValues.CompetitiveSet,
          TimescalesTrend: filterValues.TimescalesTrend,
          Category: [],
          ProteinType: filterValues.ProteinType,
          Channel: filterValues.Channel,
          City: filterValues.City,
          Item: filterValues.Item,
          ProductSize: filterValues.ProductSize,
          ComparisionType: filterValues.ComparisionType,
          PromoType: filterValues.PromoType,
          PromoType2: filterValues.PromoType2,
          SourceType: filterValues.SourceType,
          Offer: filterValues.Offer,
        })
      );
    } else {
      dispatch(
        setFilterValue({
          Timescales: filterValues.Timescales,
          MarketSegment: filterValues.MarketSegment,
          CompetitiveSet: filterValues.CompetitiveSet,
          TimescalesTrend: filterValues.TimescalesTrend,
          Category: [data],
          ProteinType: filterValues.ProteinType,
          Channel: filterValues.Channel,
          City: filterValues.City,
          Item: filterValues.Item,
          ProductSize: filterValues.ProductSize,
          ComparisionType: filterValues.ComparisionType,
          Offer: filterValues.Offer,
        })
      );
    }
  };
  const PromoTypeChange = (data) => {
    form.setFieldsValue({
      PromoType2: [],
    });
    dispatch(
      setFilterValue({
        Timescales: filterValues.TimeScales ? filterValues.TimeScales : [],
        MarketSegment: filterValues.MarketSegment ? filterValues.MarketSegment : [],
        CompetitiveSet: filterValues.CompetitiveSet ? filterValues.CompetitiveSet : [],
        TimescalesTrend: filterValues.TimescalesTrend,
        Category: filterValues.Category ? filterValues.Category : [],
        ProteinType: filterValues.ProteinType ? filterValues.ProteinType : [],
        Channel: filterValues.Channel ? filterValues.Channel : [],
        City: filterValues.City ? filterValues.City : [],
        Item: filterValues.Item ? filterValues.Item : [],
        ProductSize: filterValues.ProductSize ? filterValues.ProductSize : [],
        ComparisionType: filterValues.ComparisionType ? filterValues.ComparisionType : [],
        PromoType: [data],
        PromoType2: filterValues.PromoType2 ? filterValues.PromoType2 : [],
        SourceType: filterValues.SourceType ? filterValues.SourceType : [],
      })
    );
  };
  const PromoTypeChange2 = (data) => {
    dispatch(
      setFilterValue({
        Timescales: filterValues.TimeScales ? filterValues.TimeScales : [],
        MarketSegment: filterValues.MarketSegment ? filterValues.MarketSegment : [],
        CompetitiveSet: filterValues.CompetitiveSet ? filterValues.CompetitiveSet : [],
        TimescalesTrend: filterValues.TimescalesTrend,
        Category: filterValues.Category ? filterValues.Category : [],
        ProteinType: filterValues.ProteinType ? filterValues.ProteinType : [],
        Channel: filterValues.Channel ? filterValues.Channel : [],
        City: filterValues.City ? filterValues.City : [],
        Item: filterValues.Item ? filterValues.Item : [],
        ProductSize: filterValues.ProductSize ? filterValues.ProductSize : [],
        ComparisionType: filterValues.ComparisionType ? filterValues.ComparisionType : [],
        PromoType: filterValues.PromoType ? filterValues.PromoType : [],
        PromoType2: [data],
        SourceType: filterValues.SourceType ? filterValues.SourceType : [],
      })
    );
  };

  const ChannelChange = (data) => {
    dispatch(
      setFilterValue({
        Timescales: filterValues.TimeScales ? filterValues.TimeScales : [],
        MarketSegment: filterValues.MarketSegment ? filterValues.MarketSegment : [],
        CompetitiveSet: filterValues.CompetitiveSet ? filterValues.CompetitiveSet : [],
        TimescalesTrend: filterValues.TimescalesTrend,
        Category: filterValues.Category ? filterValues.Category : [],
        ProteinType: filterValues.ProteinType ? filterValues.ProteinType : [],
        Channel: [data],
        City: filterValues.City ? filterValues.City : [],
        Item: filterValues.Item ? filterValues.Item : [],
        ProductSize: filterValues.ProductSize ? filterValues.ProductSize : [],
        ComparisionType: filterValues.ComparisionType ? filterValues.ComparisionType : [],
        PromoType: filterValues.PromoType ? filterValues.PromoType : [],
        PromoType2: [data],
        SourceType: filterValues.SourceType ? filterValues.SourceType : [],
      })
    );
  };

  const handlePtChange = (data) => {
    setProteinTypeCount(data?.length);
  };

  const itemChange = (data) => {
    setItemCout(data.length);
  };
  const cityChange = (data) => {
    setcityCount(data.length);
  };
  const productSizeChange = () => {};
  function disabledDate(current) {
    const endOfApril2024 = moment("2024-09-01");
    return current >= endOfApril2024;
  }
  const modalContent = useMemo(() => {
    return (
      <Form form={form} layout='vertical' autoComplete='off' onFinish={onFinish} initialValues={filterValues ? filterValues : {}}>
        <Row className='mb-2'>
        {name == "OpeningTrends" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    TimePeriod{" "}
                  </span>
                }
                name='TimePeriodOpenings'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: "Monthly", label: "Monthly" },
                    { value: "3 Monthly", label: "3 Monthly" },
                    { value: "6 Monthly", label: "6 Monthly" },
                    { value: "Yearly", label: "Year To Date" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {name == "visits1" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    TimePeriod{" "}
                  </span>
                }
                name='TimePeriod'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: "Weekly", label: "Weekly" },
                    { value: "Monthly", label: "Monthly" },
                    { value: "3 Monthly", label: "3 Monthly" },
                    { value: "6 Monthly", label: "6 Monthly" },
                    { value: "Yearly", label: "Year To Date" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          
          {name == "Promo" && activePromo != 1 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Time Period{" "}
                  </span>
                }
                name='TimePeriodPromo'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: "Weekly", label: "Weekly" },
                    { value: "Monthly", label: "Monthly" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                  onChange={(e) => {
                    if (e === "Weekly" || e == "Weekly") {
                      setTimePeriodPromoFlag(true);
                      localStorage.setItem("TimePeriodPromoFlag", true);
                    } else if (e === "Monthly" || e == "Monthly") {
                      setTimePeriodPromoFlag(false);
                      localStorage.setItem("TimePeriodPromoFlag", false);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {(activeTab ||
          name=="OpeningsSnapshot"||
            name == "Home" ||
            name == "snapshotTarrif" ||
            activeTabProductSnapshot == 3 ||
            activeTabProductSnapshot == 4 ||
            (name == "Promo" && activePromo == 2 && !TimePeriodPromoFlag && filterValues.TimePeriodPromo.includes("Monthly")) ||
            (name == "Promo" && activePromo == 3 && !TimePeriodPromoFlag && filterValues.TimePeriodPromo.includes("Monthly")) ||
            (name == "Promo" && activePromo == 4 && !TimePeriodPromoFlag && filterValues.TimePeriodPromo.includes("Monthly"))) &&
            PromotionsTrendsflag != "PromotionsTrendsflag" && (
              <Col span={6}>
                <Form.Item
                  label={
                    <span className='fw-500 m-1' style={{ fontFamily: "Quicksand" }}>
                      Timescales
                    </span>
                  }
                  name='Timescales'
                >
                  <Select
                    onChange={handleDateChange}
                    loading={fetchingData}
                    disabled={fetchingData}
                    initialValues={[]}
                    destroyOnClose={true}
                    options={TimescaleValue}
                    className='shadow-sm '
                    placeholder='Click to select'
                    style={{
                      width: 250,
                    }}
                    size='large'
                  />

               
                </Form.Item>
              </Col>
            )}
          {name == "Promo" &&
            activePromo1 != 5 &&
            activePromo != 1 &&
            activeTabProductSnapshot != 3 &&
            activeTabProductSnapshot != 4 &&
            (TimePeriodPromoFlag || filterValues.TimePeriodPromo.includes("Weekly")) &&
            TimePeriodPromoFlag != "TimePeriodPromoFlag" && (
              <Col span={6}>
                <Form.Item
                  label={
                    <span className='fw-500 m-1' style={{ fontFamily: "Quicksand" }}>
                      Timescales
                    </span>
                  }
                  name='TimescalesWeekly'
                >
                  <Select
                    onChange={handleDateChange}
                    loading={fetchingData}
                    disabled={fetchingData}
                    initialValues={[]}
                    destroyOnClose={true}
                    options={TimePeriodPromo}
                    className='shadow-sm '
                    placeholder='Click to select'
                    style={{
                      width: 250,
                    }}
                    size='large'
                  />
                </Form.Item>
              </Col>
            )}

          {(activeTabTrends || name == "OpeningTrends"  ||  (name == "Product" && activeTabProductSnapshot != 3 && activeTabProductSnapshot != 4) || (name == "Promo" && activePromo == 1) || name == "visits1" || activePromo1 == 5) && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='fw-500 m-1' style={{ fontFamily: "Quicksand" }}>
                    Timescales {filterValues.TimescalesTrend[0] + "-" + filterValues.TimescalesTrend[1]}  
                    {/* <Button style={{ background: "#1DB9DE" ,color:"white"}}  icon={<UndoOutlined />} size={"small"} className="custome-button" /> */}
                  </span>
                }
              >
                <RangePicker
                //  defaultValue={'2015/01/01'}   // you have to change this as per your requirment or you can create gloabal function and pass date as per default timescale
                defaultPickerValue={defaultRange} 
                className='shadow-sm '
                  style={{
                    width: 250,
                  }}
                  size='large'
                  allowClear
                  onChange={handleDateRangeChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                  disabledDate={disabledDate}
                />
             
              </Form.Item>
            </Col>
          )}

          <Col span={6}>
            <Form.Item
              name='MarketSegment'
              label={
                <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                  Market Segment{" "}
                  <span className={Market_SegmentCount ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                    {Market_SegmentCount ? Market_SegmentCount : ""}
                  </span>
                </span>
              }
            >
              <Select
                mode='multiple'
                destroyOnClose={true}
                initialValues={[]}
                style={{
                  width: 250,
                }}
                allowClear
                className='shadow-sm dont-show'
                placeholder='Click to select'
                options={MarketsegmentValue}
                size='large'
                onChange={handleMsChange}
                loading={fetchingData}
                disabled={fetchingData}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='CompetitiveSet'
              label={
                <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                  Competitive Set{" "}
                  <span className={CompetitiveCount ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                    {CompetitiveCount ? CompetitiveCount : ""}
                  </span>
                </span>
              }
            >
              <Select
                destroyOnClose={true}
                style={{
                  width: 250,
                }}
                className='shadow-sm dont-show'
                allowClear
                mode='multiple'
                placeholder='Click to select'
                options={CompetitivesetValue}
                size={"large"}
                onChange={handleCsChange}
                loading={fetchingData}
                disabled={fetchingData}
              />
            </Form.Item>
          </Col>
          {name == "Home" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Comparison Type{" "}
                  </span>
                }
                name='ComparisionType'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: "Vs Last Month", label: "Vs Last Month" },
                    { value: "Vs Last Year", label: "Vs Last Year" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
           {( name == "OpeningTrends" || name=="OpeningsSnapshot") && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    Region
                  </span>
                }
                name='RegionOpenings'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={OpeningsRegionValue}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {(name == "visits1"  || name == "snapshotTarrif" ) && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    Region
                  </span>
                }
                name='Region'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={RegionValue}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {(name == "visits1" || name == "snapshotTarrif") && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    DayTime
                  </span>
                }
                name='DayTime'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: "All", label: "All" },
                    { value: "Morning", label: "Morning" },
                    { value: "Day", label: "Day" },
                    { value: "Evening", label: "Evening" },
                    { value: "Night", label: "Night" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {name!="OpeningsSnapshot"&& name!="OpeningTrends" && name != "Home" && name != "visits1" && name != "snapshotTarrif" && activeTabProductSnapshot!=4  &&  activePromo != 3  && activePromo != 4  && (
            <Col span={6}>
              <Form.Item label={"Category"} name='Category'>
                <Select
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  initialValues={[]}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  options={CategoryValue}
                  size={"large"}
                  onChange={handleCcChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}

          {name != "Home" &&name!="OpeningsSnapshot" && name != "Promo" && name != "visits1" && name != "snapshotTarrif" && name != "OpeningTrends" && activeTabProductSnapshot != 4 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    Protein Type{" "}
                  </span>
                }
                name='ProteinType'
              >
                <Select
                  initialValues={[]}
                  placeholder='Click to select'
                  style={{
                    width: 250,
                  }}
                  className='shadow-sm dont-show'
                  allowClear
                  options={ProteinTypeValue}
                  size={"large"}
                  onChange={handlePtChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}

          {activeTab !== 2 && name != "Home" && name!="OpeningsSnapshot"&& name != "Product" && name != "Promo" && name != "OpeningTrends" && name != "visits1" && name != "snapshotTarrif" && (
            <>
              <Col span={6}>
                <Form.Item
                  label={
                    <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                      {" "}
                      Channel{" "}
                    </span>
                  }
                  name='Channel'
                >
                  <Select
                    placeholder='Click to select'
                    style={{
                      width: 250,
                    }}
                    className='shadow-sm dont-show'
                    options={ChannelValue}
                    loading={fetchingData}
                    disabled={fetchingData}
                    onChange={ChannelChange}
                    size={"large"}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          { name!="OpeningsSnapshot" && name != "Home" && name != "Product" && name != "Promo" && name != "OpeningTrends" && name != "visits1" && name != "snapshotTarrif" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Item{" "}
                    <span className={itemCount ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                      {itemCount ? itemCount : ""}
                    </span>
                  </span>
                }
                name='Item'
              >
                <Select
                  mode='multiple'
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={ItemValue}
                  size='large'
                  onChange={itemChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}

          {( activeTab!=3) && name != "visits1" && name != "Home" && name != "snapshotTarrif" && name != "Product" && activePromo != 2 && activePromo != 3 && activePromo != 4 && activePromo1 != 5 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    City{" "}
                    <span className={cityCount ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                      {cityCount ? cityCount : ""}
                    </span>
                  </span>
                }
                name='City'
              >
                <Select
                  mode='multiple'
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show '
                  placeholder='Click to select'
                  options={CityValue}
                  size='large'
                  onChange={cityChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                  listHeight={210}
                />
              </Form.Item>
            </Col>
          )}
          {activeTabTrends && activeTabTrends == 1 && name != "Promo" && name != "visits1" && name != "snapshotTarrif" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Product Size{" "}
                  </span>
                }
                name='ProductSize'
              >
                <Select
                  mode='multiple'
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={ProductSize}
                  size='large'
                  onChange={productSizeChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {name == "Promo" && activePromo != 4 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Channel{" "}
                  </span>
                }
                name='SourceType'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={SourceTypeValue}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {name == "Promo" && activePromo != 3 && activePromo != 4 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Promo Type{" "}
                  </span>
                }
                name='PromoType'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={PromoTypeValue}
                  size='large'
                  onChange={PromoTypeChange}
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}
          {name == "Promo" && activePromo != 3 && activePromo != 4 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Promo Type2{" "}
                  </span>
                }
                name='PromoType2'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={PromoType2Value}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                  onChange={PromoTypeChange2}
                />
              </Form.Item>
            </Col>
          )}
          {name == "Promo" && activePromo == 1 && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    Promo Length{" "}
                    <span className={PromoTypeLengthCount ? " p-1 rounded text-white fw-bold " : ""} style={{ background: "#1DB9DE" }}>
                      {PromoTypeLengthCount ? PromoTypeLengthCount : ""}
                    </span>
                  </span>
                }
                name='PromoTypeLength'
              >
                <Select
                  mode='multiple'
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={PromoTypeLength}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                  onChange={handlePTLChange}
                />
              </Form.Item>
            </Col>
          )}

          {name == "Product" && (
            <Col span={6}>
              <Form.Item
                label={
                  <span className='m-1' style={{ fontFamily: "Quicksand" }}>
                    {" "}
                    Offer{" "}
                  </span>
                }
                name='Offer'
              >
                <Select
                  destroyOnClose={true}
                  style={{
                    width: 250,
                  }}
                  allowClear
                  className='shadow-sm dont-show'
                  placeholder='Click to select'
                  options={[
                    { value: 1, label: "LTO" },
                    { value: 0, label: "NOT LTO" },
                  ]}
                  size='large'
                  loading={fetchingData}
                  disabled={fetchingData}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={6}></Col>
        </Row>
        <div className='mt-2 mb-2 text-center'>
          <Button className='fw-bold shadow-sm text-center' size={"large"} htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    );
  }, [fetchingData, form, filterValues, calApi, onFinish]);

  return (
    <Modal title={<span className='fw-bold fs-5'>Filters</span>} open={isModalOpen} onOk={handleOk} footer={false} width={1200} maskClosable={false} onCancel={handleCancel} closeIcon={null}>
      {modalContent}
    </Modal>
  );
};

export default FilterModal;
